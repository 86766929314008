import Swiper from 'swiper'
import './../../scss/blocks/_cards.scss'

let swiperInstance = null

const initSlider = () => {
    swiperInstance = new Swiper('.js-cards-slider', {
        loop: false,
        slidesPerView: 'auto',
        initialSlide: 0,
        freeMode: true,
        grabCursor: true,
        width: 520,
        stopOnLastSlide: true,
        watchOverflow: true,
        centeredSlides: false,
        resistanceRatio: 0,
        slidesOffsetAfter: 0,
        loopFillGroupBlank: false,
        centeredSlidesBounds: false,
    })
}

const destroySlider = () => {
    if (swiperInstance) {
        swiperInstance.destroy()
        swiperInstance = null
    }
}

const handleResize = () => {
    if (window.innerWidth > 1100 && window.innerWidth < 1720) {
        initSlider()
    } else {
        destroySlider()
    }
}

document.addEventListener('DOMContentLoaded', () => {
    handleResize()
    window.addEventListener('resize', handleResize)
})
